<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">资源管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">平台课程</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">设置核算</a>
        </span>
      </div>
      <div class="framePage-body">
        <!-- <div class="framePage-scroll"> -->
        <div class="ovy-a">
          <div class="flexcb">
            <h3 style="padding-bottom: 20px">课程基本信息</h3>
          </div>
          <div>
            <el-form ref="ruleForm" label-width="10rem" class="courseBox">
              <el-form-item label="课程名称：" class="form-cell">
                <p>{{ ruleForm.courseName }}</p>
              </el-form-item>
              <el-form-item
                  v-show="ruleForm.trainTypeNamePath"
                  label="培训类型："
                  class="form-cell"
              >
                <p>{{ ruleForm.trainTypeNamePath }}</p>
              </el-form-item>
              <el-form-item
                  v-show="ruleForm.industryNamePath"
                  label="行业类型："
                  class="form-cell"
              >
                <p>{{ ruleForm.industryNamePath }}</p>
              </el-form-item>
              <el-form-item
                  v-show="ruleForm.postName"
                  label="岗位类型："
                  class="form-cell"
              >
                <p>{{ ruleForm.postName }}</p>
              </el-form-item>
              <el-form-item
                  v-show="ruleForm.occupationNamePath"
                  label="职业/工种："
                  class="form-cell"
              >
                <p>{{ ruleForm.occupationNamePath }}</p>
              </el-form-item>
              <el-form-item
                  v-show="ruleForm.trainLevelName"
                  label="培训等级："
                  class="form-cell"
              >
                <p>{{ ruleForm.trainLevelName }}</p>
              </el-form-item>
            </el-form>
            <el-form ref="ruleForm" label-width="10rem">
              <el-form-item label="课程详情：" prop="context" class="form-item">
                <div
                    v-html="ruleForm.context"
                    ref="editor"
                    style="width: 100%; position: relative; z-index: 2"
                />
              </el-form-item>
              <el-form-item label="课程图片：" prop="logoURL">
                <div class="img-el-upload" style="width: 256px">
                  <el-image
                      :src="ruleForm.logoURL || require('@/assets/develop.png')"
                      fit="contain"
                  ></el-image>
                </div>
              </el-form-item>
              <el-form-item label="讲师备注：" prop="lecturer">
                <p>{{ ruleForm.lecturer || "无" }}</p>
              </el-form-item>
            </el-form>
          </div>
          <div class="flexcb">
            <h3 style="padding-bottom: 20px">课程核算设置</h3>
          </div>
          <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                width="100px"
              />
              <el-table-column
                label="课程方名称"
                align="left"
                prop="providerName"
                show-overflow-tooltip
                minWidth="150"
              />
              <el-table-column
                label="课件数量"
                align="left"
                prop="kpointNum"
                show-overflow-tooltip
                minWidth="150"
              />
              <el-table-column
                label="公共课数量"
                align="left"
                prop="publicKpointNum"
                show-overflow-tooltip
                minWidth="150"
              />
              <el-table-column
                label="是否纳入核算"
                align="left"
                show-overflow-tooltip
                minWidth="150"
              >
                <template slot-scope="{ row }">
                    <el-switch
                        v-model="row.isAccounting"
                        active-color="#13ce66"
                        inactive-color="#ff4949"
                        @change="accountingChange(courseId,row.providerId,row.isAccounting)">
                    </el-switch> 
                    <span :style="{'color':row.isAccounting?'#13ce66':'#ff4949','margin-left': '10px'}">{{row.isAccounting?'纳入核算':'不纳入核算'}}</span>
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import List from "@/mixins/List";
export default {
  name: "CourseReviewDetails",
  components: {
    Empty,
  },
  mixins: [List],
  data() {
    return {
        courseId:'',//课程id
        ruleForm:{},//课程详情
    };
  },
  created() {
    this.courseId = this.$route.query.courseId;
    this.getDetail(this.courseId);
    this.getAccountingInfo(this.courseId);
  },
  methods: {
    //课程详情
    getDetail(courseId) {
      this.$post("/run/course/queryCourseDetail", {
        courseId,
      }).then((result) => {
          this.ruleForm = result.data
      });
    },
    //课程核算信息
    getAccountingInfo(courseId) {
      this.$post("/run/course/accountingInfo", {
        courseId,
      }).then((result) => {
          this.tableData = result.data.info
      });
    },
    //课程核算信息改变
    accountingChange(courseId,providerId,isAccounting){
        console.log(courseId,providerId,isAccounting);
      this.$post("/run/course/setAccounting", {
        courseId,providerId,isAccounting
      }).then((result) => {
          this.$message.success('操作成功!')
          this.getAccountingInfo(courseId)
      });
    }
  },
};
</script>
<style lang="less" scoped>
.courseBox {
  display: flex;
  flex-wrap: wrap;
  .form-cell {
    width: 50%;
  }
}
/deep/ .el-switch__label--left.is-active + .el-switch__core{
  background: #ff4949!important;
}
</style>

